import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { css } from '@emotion/core'
import { Grid } from '@material-ui/core'
import BlogSidebar from '../components/Blog/SideBar'
import PageHeader from '../components/Blog/pageHeader'
import Breadcrumbs from '../components/MainComponents/Breadcrumbs'

export default function BlogPost({ data, location }) {
  const pageData = data.wordpressWpGuide;
  return (
    <Layout PageHeader={() => <PageHeader pageTitle={pageData.title} />} >
      <SEO
        title={pageData.title}
        url={location.pathname}
      />
      <Breadcrumbs url={location.pathname} />
      <Grid container spacing={3}>
        <Grid item sm={8}>
          <div
            css={theme => css`
              word-break: break-word;
              img {
                width: 100%;
                height: auto;
              }
              ${theme.blogPosts}
          `}
            dangerouslySetInnerHTML={{ __html: pageData.content }} />
        </Grid>
        <Grid item sm={4}>
          <BlogSidebar />
        </Grid>
      </Grid>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    wordpressWpGuide(slug: {eq: $slug}) {
        title
        content 
    }
  }
`